import React from 'react'

const Videos: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 20H62V52C62 54.2091 60.2091 56 58 56H6C3.79086 56 2 54.2091 2 52V20Z"
        fill="white"
        stroke="#00AD0C"
        strokeWidth="2"
      />
      <path
        d="M2 12C2 9.79086 3.79086 8 6 8H58C60.2091 8 62 9.79086 62 12V20H2V12Z"
        fill="#CDFFC7"
        stroke="#00AD0C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M30.9923 30.7099C29.659 29.948 28 30.9107 28 32.4464V41.5536C28 43.0893 29.659 44.052 30.9923 43.2901L38.9611 38.7365C40.3048 37.9687 40.3048 36.0313 38.9611 35.2635L30.9923 30.7099Z"
        stroke="#00AD0C"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47 16C48.1046 16 49 15.1046 49 14C49 12.8954 48.1046 12 47 12C45.8954 12 45 12.8954 45 14C45 15.1046 45.8954 16 47 16Z"
        fill="#00AD0C"
      />
      <path
        d="M55 16C56.1046 16 57 15.1046 57 14C57 12.8954 56.1046 12 55 12C53.8954 12 53 12.8954 53 14C53 15.1046 53.8954 16 55 16Z"
        fill="#00AD0C"
      />
    </svg>
  )
}

export default Videos
